import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setStep } from '../../../store/actions/programActions';
import {
    hideLoading,
    showLoading,
} from '../../../store/actions/loadingActions';
import { getTierList, saveTierList } from '../../../services/api/tierApi';
import { notifyError } from '../../../store/actions/notificationActions';
import { getTierListPayload } from '../../../services/api/apiPayloads';
import { hasDuplicateTiers } from '../../../services/tierUtils';
import { normalizeString } from '../../../services/typeUtils';
import * as C from '../../../services/constants';

import deleteIcon from '../../../resources/images/delete-icon.svg';

const tierReqObj = {
    name: '',
    description: '',
    points: '',
    ranking: null,
    loyaltyTierId: null,
    status: 'DRAFT',
    action: '',
};
export const DefineTiers = () => {
    const dispatch = useDispatch();
    const { auth, program } = useSelector((state) => state);

    const { programInfo, step } = program;
    const { market, category, brand } = auth.user;

    const [nextEnabled, setNextEnabled] = useState(false);
    const [tiers, setTiers] = useState([]);
    const [deletedTiers, setDeletedTiers] = useState([]);

    const addTier = () => {
        setTiers((prevTiers) => {
            const newTier = {
                ...tierReqObj,
                ranking: prevTiers.length + 1,
                action: C.TIER_ACTION_CREATE,
            };
            return [...prevTiers, newTier];
        });
    };

    const deleteTier = (index, loyaltyTierId) => {
        if (loyaltyTierId) {
            setDeletedTiers((prevDeletedTiers) => {
                let deletedTier = tiers.find(
                    (tier) => tier.loyaltyTierId === loyaltyTierId
                );
                return [
                    ...prevDeletedTiers,
                    { ...deletedTier, action: C.TIER_ACTION_DELETE },
                ];
            });
        }
        const updatedTiers = tiers.filter((tier, idx) => idx !== index);
        const updatedTierRank = updatedTiers.map((tier, index) => {
            return { ...tier, ranking: index + 1 };
        });
        setTiers(updatedTierRank);
    };

    const handleChange = (e, ranking) => {
        const { value, name } = e.target;
        const updatedTierData = tiers.map((tier) => {
            if (tier.ranking === ranking) {
                switch (name) {
                    case 'name': {
                        if (
                            C.REGEX_TIER_NAME.test(normalizeString(value)) ||
                            value === ''
                        ) {
                            return { ...tier, name: value };
                        }
                        return tier;
                    }
                    case 'points': {
                        if (C.REGEX_TIER_POINTS.test(value) || value === '') {
                            return { ...tier, points: value };
                        }
                        return tier;
                    }
                    case 'description': {
                        if (
                            C.REGEX_TIER_DESCRIPTION.test(
                                normalizeString(value)
                            ) ||
                            value === ''
                        ) {
                            return { ...tier, description: value };
                        }
                        return tier;
                    }
                    default: {
                        return tier;
                    }
                }
                // const updatedValue = value !== undefined ? value : '';
                // return { ...tier, [name]: updatedValue };
            } else {
                return tier;
            }
        });
        setTiers(updatedTierData);
    };

    const validateTierData = () => {
        const requiredFields = ['name', 'points', 'description'];

        const isInvalid = tiers.some((tier) => {
            return requiredFields.some((field) => {
                return (
                    tier[field] === null ||
                    tier[field] === undefined ||
                    tier[field] === ''
                );
            });
        });

        return !isInvalid;
    };

    const handleClick = () => {
        if (nextEnabled) {
            const tierListBody = getTierListPayload([
                ...deletedTiers,
                ...tiers,
            ]);
            if (hasDuplicateTiers(tiers)) {
                dispatch(notifyError('Error!', 'Duplicate values found'));
            } else {
                dispatch(showLoading());
                saveTierList(market, category, brand, tierListBody)
                    .then(() => dispatch(setStep(step + 1)))
                    .catch((error) => {
                        dispatch(
                            notifyError('Error!', 'Unable to save tiers!')
                        );
                    })
                    .finally(() => dispatch(hideLoading()));
            }
        }
    };
    useEffect(() => {
        dispatch(showLoading());
        getTierList(market, category, brand)
            .then((response) => {
                if (response.data.length > 0) {
                    const existingTiers = response.data.map((tier, idx) => ({
                        name: tier.name || '',
                        description: tier.description || '',
                        points: tier.points || 0,
                        ranking: tier.ranking || idx + 1,
                        loyaltyTierId: tier.loyaltyTierId || null,
                        status: tier.status || '',
                        action: tier.action || '',
                    }));
                    setTiers(existingTiers);
                } else {
                    setTiers((existingTiers) => {
                        const newTiers = [
                            {
                                ...tierReqObj,
                                ranking: 1,
                                points: 0,
                                action: C.TIER_ACTION_CREATE,
                            },
                            {
                                ...tierReqObj,
                                ranking: 2,
                                action: C.TIER_ACTION_CREATE,
                            },
                        ];
                        return newTiers;
                    });
                }
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to fetch tiers.'));
            })
            .finally(() => dispatch(hideLoading()));
    }, []);

    useEffect(() => {
        const isValidData = validateTierData();
        setNextEnabled(isValidData);
    }, [tiers]);
    return (
        <div className="define-tiers-container">
            <p>Add and name minimum 2 tiers for your loyalty program.</p>
            <p>*All fields are mandatory</p>
            <div className="define-tiers">
                {tiers.map((tier, index) => (
                    <div className="tier" key={index}>
                        <h4>Tier {tier.ranking}: </h4>
                        <div className="tier-input-container">
                            <div className="tier-details">
                                <div className="tier-name">
                                    <label>Tier Name: </label>
                                    <input
                                        type="text"
                                        placeholder="e.g. Bronze"
                                        name="name"
                                        onChange={(e) =>
                                            handleChange(e, tier.ranking)
                                        }
                                        value={tier.name || ''}
                                    />
                                </div>
                                <div className="tier-points">
                                    <label>
                                        Required Points to Enter Tier:{' '}
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter no. of points"
                                        name="points"
                                        onChange={(e) =>
                                            handleChange(e, tier.ranking)
                                        }
                                        value={
                                            tier.ranking === 1
                                                ? '0'
                                                : tier.points || ''
                                        }
                                        disabled={tier.ranking === 1}
                                    />
                                </div>
                            </div>
                            <div className="tier-description">
                                <label>Tier Description: </label>
                                <input
                                    type="text"
                                    placeholder="e.g. Basic entry level tier"
                                    name="description"
                                    onChange={(e) =>
                                        handleChange(e, tier.ranking)
                                    }
                                    value={tier.description || ''}
                                />
                            </div>
                        </div>
                        {tier.ranking >= 3 && (
                            <img
                                className="delete-tier"
                                src={deleteIcon}
                                alt="delete tier"
                                onClick={() =>
                                    deleteTier(index, tier.loyaltyTierId)
                                }
                            />
                        )}
                    </div>
                ))}
                <button className="add-tier" onClick={() => addTier()}>
                    + Add Tier
                </button>
            </div>
            <div className="define-tiers-cta-container">
                <button
                    className={`save-and-continue ${
                        nextEnabled ? 'active' : 'inactive'
                    }`}
                    onClick={() => {
                        handleClick();
                    }}
                >
                    Save & Continue
                </button>
            </div>
        </div>
    );
};

export default DefineTiers;
